import { useState, useEffect } from "react";

import { Storage } from '@ionic/storage';

import Player from '../model/Player';
import { UserPhoto } from "../hooks/usePhoto";
// import { UserAnswer } from "../components/AnswerComponent";

//const API = 'https://abhi4biz.daktech.dev/streets/api'; //Dev
const API = process.env.REACT_APP_API_LINK;
//const API = 'https://sots.ddms4.biz/streets/api'; //Live
const STORE = 'SOTS_streets_';

export interface Token{
    id: number;
    token: string;
    pass: string;
    expires: number;
}

export interface Tag{
  id: number;
  title: string;
  description: string;
  icon: string;
  position: number;
  hide: boolean;
  active: boolean;
}

export interface Route{
  id: number;
  title: string;
  description: string;
  position: number;
  link: string;
  active: boolean;
}

export interface Location{
  id: number;
  title: string;
  address: string;
  icon: string;
  description: string;
  lat: number;
  lng: number;
  live: boolean;
  active: boolean;
  vote_count: number;
  pre_event: boolean;
  main: boolean;
  star: boolean;
  open: string;
  closed: string;
  created: string;
  modified: string;
  tags: Tag[];
  votes?: [];
}

export interface Code{
  id: number;
  title: string;
  url: string;
  code?: string;
  note: string;
  upload_photo: boolean;
  question: any;
  submission?: {
    image?: string;
    answer?: number;
    code: string;
    correct: boolean;
    submitted: boolean;
  }
}

export interface UserCode{
  id: number;
  user: string | undefined;
  location: string;
  code?: string;

  image?: string; 
  answer?: string;
  socials?: string;

  status: {
    found: boolean;
    accessed: boolean;
    completed: boolean;
  },
  correct?: boolean;
  submitted?: boolean;
}

export interface Api{
    isAuthed: boolean;
    online: boolean;
    loading: boolean;
    agree: boolean;
    clearAgree: () => void;
    player: Player | undefined;
    savePlayer: (player: Player) => any;
    setPlayer: (player: Player) => any;//Save to local
    sendCode: (code:string) => Promise<boolean>;
    sendEmail: () => void;
    emailToken: Token | undefined;
    cancelEmail: () => void;
    updateTag: (tag: Tag) => boolean;
    locations: Location[];
    routes: Route[];
    tags: Tag[];
    codes: Code[];
    likeLocation: (location: Location) => Promise<boolean>;
    checkCode: (code: string) => Promise<boolean>;
    getLocations: () => Promise<boolean>;
    getStars: () => Promise<boolean>;
    userCodes: UserCode[];
    submitCode:  (userCode: UserCode) => Promise<boolean>;
    findNewCode: (codeInput: number, userLocation: string) => Promise<boolean>;
    updateUserCode: (userCode: UserCode, status: string) => any;
    getUserCode: (codeId: number) => UserCode | undefined;
}

export const useServerConnection = () => {
    //Required for auth
    const [token, setToken] = useState<Token>();
    const [emailToken, setEmailToken] = useState<Token>();
    const [isAuthed, setIsAuthed] = useState<boolean>(false);

    const [online, setOnline] = useState<boolean>(true);
    //Used to show loading indicator - stops double submit
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    //Agree to TCs
    const [agree, setAgree] = useState(false);

    //Local data
    const [player, _setPlayer] = useState<Player>();
    
    const [locations, setLocations] = useState<Location[]>([]);
    const [routes, setRoutes] = useState<Route[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [codes, setCodes] = useState<Code[]>([]);

    const [userCodes, setUserCodes] = useState<UserCode[]>([]); 

    const setPlayer = async (tmp: Player)=>{
      await store.set(`${STORE}player`,tmp);
      _setPlayer(tmp);
    }

    //Setup offline storage
    const store = new Storage();
    store.create();

    useEffect(() => {
      window.addEventListener("offline", () => {
        setOnline(false);
      });
      window.addEventListener("online", () => {
        setOnline(true);
      });
  
      return () => {
        window.removeEventListener("offline", () => {
          setOnline(false);
        });
        window.removeEventListener("online", () => {
          setOnline(true);
        });
      };
    }, []);

    useEffect(() => {
      if(!loaded) return;

      console.log('Load Locations...');
      getLocations();
      
    }, [loaded]);

    //On first load
    useEffect(() => {
        console.log('API useEffect');
        (async function getStorage() {
          //Load all data from local storage
          console.log('Load from local storage');

          const tmpToken:Token = await store.get(`${STORE}token`);
          setToken(tmpToken);

          const tmpEmailToken:Token = await store.get(`${STORE}email_token`);
          setEmailToken(tmpEmailToken);
          
          const tmpPlayer = await store.get(`${STORE}player`);
          _setPlayer(tmpPlayer);

          const tmpAgree = await store.get(`${STORE}agree`);
          setAgree(tmpAgree ? tmpAgree : false);

          const tmpUserCodes: UserCode[] = await store.get(`${STORE}user_codes`);
          setUserCodes(tmpUserCodes); 

          setLoaded(true);
    
        })();
      }, []);

      useEffect(() => {
        if(!online) return;
        console.log('API: token set check auth');
        if(token && !isAuthed){
            //Try authencate with token, 
            (async function inlineAsync() {
                const result = await checkAuthed(token);
                if(result){
                    setIsAuthed(true);
                }else{
                    //store.set(`${STORE}token`,undefined);
                    setToken(undefined);
                }
            })();
        }
      }, [token, isAuthed, online]);

      function sleep(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      const clearAgree = () => {
        console.log('clearAgree');
        store.set(`${STORE}agree`,true);
        setAgree(true);
      };

      const updateTag = (updatedTag: Tag) => {
        let tempTags = tags.map((tag:Tag) => (tag.id === updatedTag.id)? updatedTag : tag);

        setTags(tempTags);
        return true;
      };

      const checkAuthed = async (tmpToken: Token) => {
        console.log("checkAuthed",tmpToken);
        try {
          const response = await fetch(`${API}/players.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi', id: tmpToken.id, token: tmpToken.token, pass: tmpToken.pass})
            }
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          var data = await response.json();
          if(data?.status === 200){
            console.log("checkAuthed",'Success');
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        console.log("checkAuthed",'Failed');
        return false;
      };


      const checkCode = async (tmpCode: string) => {
        console.log("checkCode",tmpCode);
        if(!tmpCode ||!token) return false;
        try {
          const response = await fetch(`${API}/codes/check.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
            },
            method: 'PUT',
            body: JSON.stringify({code: tmpCode}) 
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          var data = await response.json();
          if(data?.status === 200 && data?.code){
            console.log("checkCode",'Success');

            const tmpCode :Code = data.code;

            const newUserCode: UserCode = {
              id: tmpCode.id,
              user: player?.email,
              code: tmpCode?.code,
              location: '',
              status: {
                found: true,
                accessed: false,
                completed: false
              }
            }

            //Only add if new - Note: Code will be all caps
            if (api.userCodes === null){
              setUserCodes([newUserCode]);
              await store.set(`${STORE}user_codes`, [newUserCode]);
            } else if(!api.userCodes.find(uCode => uCode.id === newUserCode.id)){
              setUserCodes([...userCodes, newUserCode]);
              await store.set(`${STORE}user_codes`, [...userCodes, newUserCode]);
            }
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        console.log("checkCode",'Failed');
        return false;
      };


      const likeLocation = async (tmpLocation: Location) => {
        console.log("likeLocation",tmpLocation);
        if(!tmpLocation ||!token) return false;
        try {
          const response = await fetch(`${API}/locations/vote/${tmpLocation.id}.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
            },
            method: 'POST',
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          var data = await response.json();
          if(data?.status === 200 && data?.location){
            console.log("likeLocation",'Success');

            const location :Location = data.location;
            //Update with new likes
            setLocations( locations.map( loc => loc.id === location.id ? location : loc ));
            
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        console.log("likeLocation",'Failed');
        return false;
      };

      const savePlayer = async (tmpPlayer: Player) => {
        console.log(tmpPlayer);
        //return {status: 200};
        
        setLoading(true);
        console.log("savePlayer",tmpPlayer);
        try {
            //If update
            let url, options;
            if(tmpPlayer.id && token){
                url = `${API}/players/edit/${tmpPlayer.id}.json`;
                options = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
                    },
                    method: 'PUT',
                    body: JSON.stringify(tmpPlayer) 
                  };
            }else{//New
                url = `${API}/players/add.json`;
                options = {
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(tmpPlayer) 
                  };
            }

            const response = await fetch(url,options);
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }

          var data = await response.json();
          if(data?.status === 200 && data?.player){
            setPlayer(data.player);
            store.set(`${STORE}player`,data.player);
          }

          if(data?.token && data?.token.id){//If token update
            setIsAuthed(true);//set Authed
            setToken(data.token);//Update token
            store.set(`${STORE}token`,data.token);
          }

          setLoading(false);
          //Send data back so it will show the errors
          return data;

        } catch (error) {
          console.log(error);
        }
      
        console.log("savePlayer",'Failed');
        setLoading(false);
        return false;
      };

      const getStars = async () => {
        if(!online) return false;

        console.log("getStars");
        try {
            const response = await fetch(`${API}/codes.json`,{
            headers: {
                'Content-Type': 'application/json'
            }
            });
            
            if (!response.ok) { 
              throw Error(response.statusText);
            }
            var data = await response.json();
            if(data?.codes){
              setCodes(data?.codes);
              console.log('getStars',data?.codes);
              //await store.set(`${STORE}cases`,data?.tripCases);
            }

            return true;
  
        } catch (error) {
            console.log(error);
        }
        
        console.log("getStars",'Failed');
        return false;
    };


    const submitCode = async (userCode: UserCode) => {
      console.log("submitCode",userCode);
        if(!token) return false;
        try {
          const response = await fetch(`${API}/codes/check.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
            },
            method: 'PUT',
            body: JSON.stringify({
              code: userCode.code,
              image: userCode.image,
              answer: userCode.answer,
              socials: userCode.socials
            }) 
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          var data = await response.json();
          if(data?.status === 200 && data?.code){
            console.log("checkCode",'Success');
            //update with submitted
            updateUserCode(userCode, "completed");
            
            return true;
          }

        } catch (error) {
          console.log(error);
        }
      
        console.log("submitCode",'Failed');
        return false;
  };

    const getLocations = async () => {
        if(!online) return false;

        console.log("getLocations");
        try {

          let headers;
          if(token){
            headers = {
              'Content-Type': 'application/json',
              'Authorization': token ? JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass}) : ''
            };
          }else{
            headers = {
              'Content-Type': 'application/json',
            };
          }

              const response = await fetch(`${API}/locations.json`,{
                headers
                });
            
            
            if (!response.ok) { 
              throw Error(response.statusText);
            }
            var data = await response.json();
            if(data?.locations){
              setLocations(data?.locations);
            }

            if(data?.routes){
              setRoutes(data?.routes);
              //await store.set(`${STORE}cases`,data?.tripCases);
            }
            if(data?.tags){
              const tmpTags: Tag[] = data?.tags.map((tag: Tag) => {
                let tmpTag = tag; 
                tmpTag.hide = false; 
                return tmpTag; 
              });
              setTags(tmpTags);
              //await store.set(`${STORE}cases`,data?.tripCases);

            }
            return true;
        } catch (error) {
            console.log(error);
        }
        console.log("getLocations",'Failed');
        return false;
    };

    const sendCode = async (code:string) => {
        setLoading(true);
        try {
          const response = await fetch(`${API}/auth.json`,{
            method: 'POST',
            body: JSON.stringify(
              {
                code: code,
                token: emailToken
              }
              ),
            headers: {
              'Content-Type': 'application/json'
            },
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
    
          var data = await response.json();
          console.log(data);
          if(data?.status == 200 && data?.token?.pass && data?.player){
            setIsAuthed(true);
            setToken(data.token);
            store.set(`${STORE}token`,data.token);

            setPlayer(data.player);

            store.set(`${STORE}email_token`,undefined);
            setEmailToken(undefined);

            setLoading(false);
            return true;
          }
    
        } catch (error) {
          console.log(error);
        }
    
        setLoading(false);
        return false;
    };

    const getUserCode = (codeId: number) => {
      return userCodes.find(code => code.id === codeId);
    }

    const findNewCode = async (codeInput: number, userLocation: string) => {

      const codeInputString = JSON.stringify(codeInput);

      const codeFound: Code | undefined = codes.find(code => code.title === codeInputString);

      let checkUserCodes: UserCode | undefined = undefined;

      if (userCodes !== null){
        checkUserCodes = userCodes.find(code => code.code === codeInputString);
      }


      if (codeFound !== undefined) {

        console.log("Code Found: " + codeFound.title);

        if (checkUserCodes === undefined){
          const newUserCode: UserCode = {
            id: codeFound.id,
            user: player?.email,
            location: userLocation,
            code: codeInputString,
            status: {
              found: true,
              accessed: false,
              completed: false
            }
          }
  
          const tmpUserCodes: UserCode[] = userCodes === null ? [] : userCodes;
          tmpUserCodes.push(newUserCode); 
  
          setUserCodes(tmpUserCodes); 
          await store.set(`${STORE}user_codes`, tmpUserCodes);


        }

        return true


      } else {
        console.log("Code Not Found!")
        return false;
      }

    }

    const updateUserCode = async(userCode: UserCode, status: string) => {
      console.log("Update Code");
      
      const tmpUserCodes: UserCode[] = userCodes; 

      tmpUserCodes.map(code => {
        if (userCode.id === code.id){
          if (status === "accessed"){
            code.status.accessed = true;
          } else if (status === "completed"){
            code.status.completed = true;
          } else if (status === "submission") {
            code = userCode;
            submitCode(userCode); 
          }
        }
      })

      setUserCodes(tmpUserCodes); 

      await store.set(`${STORE}user_codes`, tmpUserCodes);
    }

    const sendEmail = async () => {

        if(!player?.email) return false;
        
        setLoading(true);
        
        try {
          const response = await fetch(`${API}/auth.json`,{
            method: 'POST',
            body: JSON.stringify({email: player?.email}),
            headers: {
              'Content-Type': 'application/json'
            },
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          var data = await response.json();
          if(data?.status == 200 && data.token){
            setEmailToken(data.token);
            await store.set(`${STORE}email_token`,data.token);
            setLoading(false);
            return true;
          }
        } catch (error) {
          console.log(error);
          
        }
    
        setLoading(false);
        return false;
    };

    const cancelEmail = () => {
      setEmailToken(undefined);
      store.set(`${STORE}email_token`,undefined);
    };

    const api: Api = {
        isAuthed,
        online,
        loading,
        agree,
        clearAgree,
        player,
        setPlayer,
        savePlayer,
        sendCode,
        submitCode,
        checkCode,
        sendEmail,
        emailToken,
        cancelEmail,
        updateTag,
        locations,
        routes,
        tags,
        getLocations,
        codes,
        getStars,
        likeLocation,
        userCodes,
        updateUserCode,
        findNewCode,
        getUserCode
    };

    return api; 

};

export default useServerConnection;
