import { IonButton, IonCard, IonCardContent, IonCheckbox, IonContent, IonFooter, IonHeader, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';

import './Trails.css';

import {Api, Route} from '../data/api';
import React from 'react';
import Header from '../components/Header';
import FooterImage from '../components/FooterImage';

const waiting = '/resources/reading_map.png';


const Trails: React.FC<{api: Api}> = ({api}) => {


  return (
    <IonPage>
      <IonContent fullscreen>
        <Header title="Trails">
        </Header>
        {api.routes.length > 0 ? api.routes.map((route: Route, index: number)=>
          <IonButton className="ion-margin" key={index} expand="block" fill="outline" href={route.link} target='_blank'>{route.title}</IonButton>
        ) : <IonCard className="card-details">
          <IonCardContent style={{textAlign:'center'}}>
          <img src={waiting}/>
          <p>The Stable Team is mapping the cool Christmas light displays and creating the guided trails!</p>
          <p>Check back soon for your amazing Christmas Light Trails!</p>
          </IonCardContent>
          </IonCard>}
      </IonContent>
      <IonFooter>
        <FooterImage></FooterImage>
      </IonFooter>
    </IonPage>
  );
};

export default Trails;
