import { IonButton,IonToolbar,IonTitle,IonButtons,IonBackButton,IonIcon } from '@ionic/react';

import { arrowBackOutline} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import './Header.css'; 
import '../pages/Style.css';

interface ContainerProps { 
  title: string, 
  hideBack?: boolean,
  backCallback?: any,
}

const Header: React.FC<ContainerProps> = ({title, hideBack, backCallback}) => {
  const sotsLogo = "/resources/logo-sots.png";
  const appLogo = "/resources/icon.png";
  
  
  return (
    <IonToolbar>
      
      <IonButtons  slot='start'>
        {(hideBack) ? '' : backCallback ? <IonButton color="default" onClick={backCallback} ><IonIcon slot="icon-only" icon={arrowBackOutline}  /></IonButton> : <></>}
        
    
    </IonButtons>
    
    <IonTitle className='ion-text-center'><div className="fancy" style={{fontSize: '1.4rem'}}>{title}</div></IonTitle>

    <IonButtons  slot='end'>
    <IonButton>
    <img src="/img/SotsStar.png" height='32px'/></IonButton>
    </IonButtons>
    </IonToolbar>
  );
};

export default Header;
