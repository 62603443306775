import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
  useIonLoading,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cogOutline, ellipse, homeOutline, informationCircleOutline, mapOutline, navigateCircleOutline, searchCircleOutline, square, starOutline, triangle } from 'ionicons/icons';
import Debug from './pages/Debug';
import Map from './pages/Map';
import Trails from './pages/Trails';
import Home from './pages/Home';
import Star from './pages/Star';
import Signup from './pages/Signup';
import Filter from './pages/Filter';
import './App.css';
import './pages/Style.css'; 


import useGps from './data/gps';
import useServerConnection, {Api} from './data/api'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect } from 'react';
import Daktech from './pages/Daktech';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';

import preval from 'preval.macro'; 

const App: React.FC = () => {
  const gps = useGps();
  const api = useServerConnection();
  const [isLoading, loadingComplete] = useIonLoading();

  const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

  useEffect(() => {
    if(api.loading){
      isLoading();
    }else{
      loadingComplete();
    }

  },[api.loading]);
  
  return (
  <IonApp>
    <IonReactRouter>
      <IonTabs className="tab-color">
        <IonRouterOutlet>
          <Route path="/home">
            <Home />
          </Route>
          <Route exact path="/map">
            <Map gps={gps} api={api}/>
          </Route>
          <Route path="/register">
            {api.player && api.isAuthed ? 
              <Map gps={gps} api={api}/>
              : 
              <Signup api={api} />
              }

          </Route>
          <Route path="/trails">
            <Trails api={api}  />
          </Route>
          <Route exact path="/debug">
            <Debug gps={gps} api={api} />
          </Route>
          <Route exact path="/about" render={props => <Daktech {...props} build={dateTimeStamp} />}/>
          <Route exact path="/term" render={props => <TermsPage {...props} />}/>
          <Route exact path="/privacy" render={props => <PrivacyPage {...props} />}/>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/home">
            <IonIcon icon={homeOutline} />
            <IonLabel><div style={{fontSize: '0.8rem'}}>Home</div></IonLabel>
          </IonTabButton>
          <IonTabButton tab="map" href="/map">
            <IonIcon icon={mapOutline} />
            <IonLabel><div style={{fontSize: '0.8rem'}}>Map</div></IonLabel>
          </IonTabButton>
          {/** not this year :(
          <IonTabButton tab="trails" href="/trails">
            <IonIcon icon={navigateCircleOutline} />
            <IonLabel><div style={{fontSize: '0.8rem'}}>Trails</div></IonLabel>
          </IonTabButton>
          */}
          <IonTabButton tab="about" href="/about">
            <IonIcon icon={informationCircleOutline} />
            <IonLabel><div style={{fontSize: '0.8rem'}}>About</div></IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
)};

export default App;
