import React,{useEffect, useState} from 'react';
import { IonContent, IonPage, 
  IonList, IonButton ,
  IonCardHeader, useIonAlert,
  IonCard, IonCardTitle, 
  IonItem, IonLabel, IonText, IonCardContent, IonFooter, IonIcon, IonGrid, IonRow, IonCol, IonInput, IonImg, IonTitle, IonCardSubtitle} from '@ionic/react';

import {Api, Location, Tag} from '../data/api';
import parse from 'html-react-parser';
import { arrowBack, globeOutline, heartCircle, heartCircleOutline, heartDislikeCircleOutline, heartOutline, locationSharp, thumbsUpOutline } from 'ionicons/icons';
import './Style.css';
import { url } from 'inspector';
import Header from '../components/Header';
import './LocationDetailPage.css';
import FooterImage from '../components/FooterImage';


const LocationDetailPage: React.FC<{
    api: Api;
    onDismiss: () => void;
    location: Location}> = ({api, onDismiss, location}) => {

    const [liked, setLiked] = useState<boolean>(false); 

    const favouriteString = location.vote_count !== 0 ? location.vote_count === 1 ? ' 1 person has liked this!' : location.vote_count + ' people have liked this!': 'Be the first to favourite!'

    useEffect(() => {
        setLiked((location.votes && location.votes.length > 0)? true:false )
        
      }, [location]);

    const handelLike = async () =>{
        
        const success = await api.likeLocation(location)

        console.log(success);
    }

return (
    <IonPage>
        <Header title={location.address}></Header>
        
        <IonContent className="page-bg background">

            <IonCard className="card-details">
            <IonCardHeader>
                <IonCardTitle>{location.title}</IonCardTitle>
            </IonCardHeader>


            <IonCardContent>
            {location.description && parse(location.description)}
            </IonCardContent>

            </IonCard>
            
            <IonCard className="card-details">
            <IonCardHeader>
            <IonTitle>Tags</IonTitle>
            </IonCardHeader>

            <IonGrid className="ion-padding-horizontal">
                <IonRow>
                    {location.tags.map((tag: Tag, index: number) => (
                        <IonCol style={{flexGrow: 0}} key={index}>
                            <IonImg style={{width: '45px'}} src={tag.icon}></IonImg>
                            <IonText style={{fontSize: '13px'}}>{tag.title}</IonText>
                        </IonCol>
                    ))}

                </IonRow>
            </IonGrid>
            {/* <IonList>
                    {location.tags.map((tag: Tag, index: number)=>
                    <IonItem key={index}>
                        <IonImg style={{width: '20%'}} src={tag.icon} />
                    </IonItem>
                    )}
            </IonList> */}
            </IonCard>

            <IonText className="ion-margin">{favouriteString}</IonText>
            {api.isAuthed ? 
            <IonButton color={liked ? "danger" : 'default'} expand="block" onClick={handelLike} className="ion-margin"><IonIcon color={liked ? 'light' : 'dark'} icon={heartOutline} /></IonButton>
                :<IonButton href="/register" expand="block">Sign in to like.</IonButton>}
            
        </IonContent>

        <IonFooter>
            <FooterImage></FooterImage>
            <div className="div-color">
            <IonGrid>
                <IonRow style={{textAlign: 'center'}}>
                    <IonCol size="6"><IonButton expand="block" onClick={onDismiss}>Go back</IonButton></IonCol>

                    <IonCol size="6"><IonButton expand="block" target="_blank" color="success" href={"https://www.google.com/maps/dir/?api=1&destination="+ location.lat + ',' + location.lng} onClick={() => console.log("Google")}>Google Maps</IonButton></IonCol>
                    
                    
                </IonRow>
            </IonGrid>
            </div>
            
            
            
        </IonFooter>
    </IonPage>
    );
};

export default LocationDetailPage;