import { IonButton,IonToolbar,IonTitle,IonButtons,IonBackButton,IonIcon, IonCardHeader, IonCardSubtitle, IonCard, IonCardContent, IonCol, IonGrid, IonRow, IonCardTitle, IonLabel, CreateAnimation } from '@ionic/react';

import { arrowBackOutline, arrowForwardOutline, arrowForwardSharp, chevronDownOutline, chevronUp, chevronUpOutline} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import './Header.css'; 
import '../pages/Style.css'; 

interface DropdownCardProps { 
  text: string, 
  header: string,
  icon: any,
  buttonText: string, 
  link: string
}

const DropdownCard: React.FC<DropdownCardProps> = ({text, header, link, buttonText, icon}) => {

  const [active, setActive] = useState<boolean>(false);
  const [animation, setAnimation] = useState<boolean>(false); 
  const [animationLink, setAnimationLink] = useState<boolean>(false); 


  const stopAnimation = () => {
    setAnimation(false); 
  }


    
  
  return (
    <IonCard>
      <IonCardHeader style={{textAlign: 'center'}} onClick={() => {setActive(!active); setAnimation(true)}}>
        <IonGrid>
          <IonRow>
            <IonCol size="2">
              <IonIcon className="ion-margin-end" style={{fontSize: 18}} icon={icon}/>
            </IonCol>
            <IonCol size='8'>
              <IonLabel className="ion-margin-vertical" style={{fontSize: 18}}>{header}</IonLabel>

            </IonCol>
            <IonCol size="2">
              <CreateAnimation
              duration={200}
              play={animation}
              onFinish={{callback: () => stopAnimation()}}
              iterations={1}
              keyframes={ [
                { offset: 1, transform: 'scale(1) rotate(180deg)' }
              ]}
              ><IonIcon className="ion-margin-horizontal" color="primary" style={{fontSize: 18}} icon={active ? chevronUpOutline : chevronDownOutline}/>
              </CreateAnimation>
            </IonCol>

          </IonRow>
        </IonGrid>
      </IonCardHeader>
      {active ? <IonCardContent>
        {text}
        <IonButton fill="clear" className="ion-margin-top" expand="block" href={link}>{buttonText}<IonIcon className="ion-margin-horizontal" icon={arrowForwardOutline}></IonIcon></IonButton>
        </IonCardContent> :
        <></>
      }

    </IonCard>
  );
};

export default DropdownCard;
