
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { callOutline, camera, linkOutline, logoFacebook, logoInstagram, logoLinkedin, logoTwitter, logoYoutube, mailOutline, videocam } from 'ionicons/icons';
import React, { useState } from 'react';
import { date } from 'yup/lib/locale';
import Header from '../components/Header';
import './Home.css'; 

const daktechLogo = "/resources/logo-daktech-blue.png";

const Daktech: React.FC<{build: any}> = ({build}) => {
  return (
    <IonPage id="view-message-page">
      <Header title='DakTech Pty Ltd'/>

      <IonContent fullscreen>
      <IonCard className="card-details">
          <IonCardHeader className='ion-text-center'>
            <img src={daktechLogo} style={{maxWidth: 300}}/>
            <IonCardSubtitle>Using technology to enhance and optimise your business processes</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText className='ion-text-center'>
              <p>
          DakTech believes in supporting and investing in the community. 
          DakTech has been providing Stable on the Strand with IT Support and Software Development since 2009.</p>
          <p>
          DakTech provides affordable Software Development and IT Support to 150+ businesses throughout Australia. 
          For trustworthy and reliable technology solutions contact DakTech today.
          </p>
          <p>
          Web App Created by DakTech
          </p>
          </IonText>
          

          <IonButton expand="block" fill="outline" target='blank' href="https://daktech.com.au" className="ion-margin-top ion-margin-horizontal">
          <IonIcon slot="start" icon={linkOutline}></IonIcon>Visit Website</IonButton>

          <IonButton expand="block" fill="outline" href="tel:0747230228" className="ion-margin-top ion-margin-horizontal">
          <IonIcon slot="start" icon={callOutline}></IonIcon>07 4723 0228</IonButton>

          <IonButton expand="block" fill="outline" href="mailto:support@daktech.com.au" className="ion-margin-top ion-margin-horizontal">
          <IonIcon slot="end" icon={mailOutline}></IonIcon>Contact Us</IonButton>
          
          <IonButtons className="ion-margin-vertical" style={{display: 'flex', justifyContent: 'center'}}>
            <IonButton href='https://www.facebook.com/DakTechAustralia/' target='blank'>
              <IonIcon slot="icon-only" icon={logoFacebook} />
            </IonButton>
            <IonButton href='https://twitter.com/DaktechA' target='blank' >
              <IonIcon slot="icon-only"  icon={logoTwitter} />
            </IonButton>

            <IonButton href='https://www.instagram.com/daktechaustralia/' target='blank' >
              <IonIcon slot="icon-only"  icon={logoInstagram} />
            </IonButton>

            <IonButton href='https://www.youtube.com/channel/UCcpkSDVquIxqoeYpxsADziw/featured' target='blank' >
              <IonIcon slot="icon-only"  icon={logoYoutube} />
            </IonButton>
            <IonButton href='https://www.linkedin.com/company/daktech-australia/mycompany/' target='blank' >
              <IonIcon slot="icon-only"  icon={logoLinkedin} />
            </IonButton>

            </IonButtons>

            <IonText className="ion-margin-start">Build From: {build}</IonText>

          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
}

export default Daktech;
