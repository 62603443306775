import { IonButton,IonToolbar,IonTitle,IonButtons,IonBackButton,IonIcon, IonFooter, IonImg } from '@ionic/react';

import { arrowBackOutline} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import './Header.css'; 

const FooterImage: React.FC<{}> = ({}) => {
  
  
  return (
        <IonImg style={{border: 'none'}} src="img/shilouettew1024px.png" />
  
    );
};

export default FooterImage;
