import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { mapOutline, navigateCircleOutline, starOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import DropdownCard from '../components/DropdownCard';
import FooterImage from '../components/FooterImage';
import FooterContainer from '../components/FooterImage';
import Header from '../components/Header';
import '../pages/Style.css';
import './Home.css';

const sotsLogo = "/resources/logo-sots.png";

const Home: React.FC = () => {

  return (
    <IonPage>
      <IonContent fullscreen>
        <Header title="Stable on the Streets"></Header>
        <IonCard className="card-details">
          <IonCardContent className="no-padding">
            <div style={{textAlign:'center'}}>
          <img className="ion-padding-bottom" src="/img/SotsStar.png" height='100px' />
          </div>
          <p>
        Welcome to the Stable on the Streets </p>
        <p>
This year Stable on the Streets begins on December 1st.</p>
        </IonCardContent>

        </IonCard>

  <DropdownCard icon={mapOutline} header={"The Interactive Map!"} text={"Use the map to find where the most exciting, creative and visual displays in Townsville are this Christmas"} link="/map" buttonText={"Show me the map!"}/>
  {/*
  <DropdownCard icon={navigateCircleOutline} 
  header={"The SOTS Trails!"} 
  text={"Here are some choices of places to visit that we believe represent the most visual in Townsville"} 
  buttonText={"Take me to the trails!"} link="/trails"/>
*/}
  <DropdownCard icon={mapOutline} 
  header={"Register your address!"} 
  text={"Start with a Star and grow your idea from there! We can’t wait to see Townsville lit up over the Christmas period, spreading a message of hope, love and joy and declaring the birth of Jesus!"} 
  buttonText={"Register Now"} link="https://sots.ddms4.biz/streets/" />
<IonCard className="card-details">
  <IonCardContent className="no-padding">
<p>
Using the online map, you can visit locations around Townsville and enjoy the variety of displays.</p>
<p>
The Stable culminates at Riverway for the 18-22nd December from 6:00- 9:30 p.m.  We really hope we can see you there.</p>
<p>Have a happy Christmas!!! </p>
<p>
The Stable team</p>
<IonGrid>
  <IonRow>
    <IonCol><IonButton color="primary" routerLink='/term' expand="block"><div style={{fontSize: '1.2rem'}}>Terms &amp; Conditions</div></IonButton></IonCol>
    <IonCol><IonButton color="primary" routerLink='/privacy' expand="block"><div style={{fontSize: '1.2rem'}}>Privacy Policy</div></IonButton></IonCol>
  </IonRow>
</IonGrid>
      </IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter>
        <FooterImage></FooterImage>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
