
import * as yup from "yup";

export default interface Player {
    id: number | undefined,
    name: string,
    phone: string,
    email: string,
    newsletter: boolean,
  }

export const PlayerSchema = yup.object().shape({
    email: yup.string().max(250).email().required()
  });

export interface PlayerErrors {
    name: any,
    phone: any,
    email: any,
    newsletter: any
  }