
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonImg,
  IonList,
  IonPage,
  IonText,
  useIonAlert,
} from '@ionic/react';
import React, { useState } from 'react';
import './Signup.css';
import { useForm } from "react-hook-form";
import CustomInput from '../components/InputContainer'
import { yupResolver } from '@hookform/resolvers/yup';

import Player, {PlayerErrors, PlayerSchema} from '../model/Player'

import {Api} from '../data/api';
import FooterImage from '../components/FooterImage';

const Signup: React.FC<{api:Api }> = ({api}) => {

  const [presentAlert] = useIonAlert();
  const [formErrors, setFormErrors] = useState<PlayerErrors>();
  
  const defaultValues:Player = api.player || {
    name: "", 
    phone:"", 
    email:"", 
    newsletter: true,
    id:undefined
  };

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(PlayerSchema)});

  const onSubmit = async (data: Player) => {
    
    const responce = await api.savePlayer(data);
    if(responce?.status !== 200){
      let $message = responce.message ? responce.message : 'Error saving, please check inputs and try again';
      presentAlert($message, [{ text: 'Ok' }]);
      responce.errors && setFormErrors(responce.errors);
    }
  };
  
  return (
    <IonPage id="view-message-page">
      
      <IonContent fullscreen>
      <IonCard>
          <IonCardHeader>
            <IonCardTitle>Sign up</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
          
          <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register("id")} />        
      <IonList>
        
        <CustomInput type="email" 
          inputText="Email" 
          specForm={formErrors?.email} 
          error={errors.email} 
          register={register("email")}></CustomInput>

      </IonList>
          <IonText>By uploading content to this site I give permission for Stable on The Streets to 
            share my images in future marketing or promotional material for the purpose of promoting Stable on the Streets. 
            I understand that this content may be displayed online and via social media outlets. </IonText>
          <IonButton expand="block" type="submit" className="ion-margin-top">
            Register
          </IonButton>
          </form>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter>
        <FooterImage></FooterImage>
      </IonFooter>
    </IonPage>
  );
}

export default Signup;
