import { useState, useEffect, useRef } from "react";


const watchCurrentLocation = (successCallback: PositionCallback, errorCallback: PositionErrorCallback) => {
    console.log('watchCurrentLocation');
    return navigator.geolocation.watchPosition(successCallback, errorCallback, {
      timeout: 3000,
      maximumAge: 500,
      enableHighAccuracy: true,
    });
}

const stopWatchingLocation = (watchId: number) => {
    console.log('stopWatchingLocation',watchId);
    navigator.geolocation.clearWatch(watchId)
}

export interface Gps{
    defaultLocation: {lat: number, lng:number};
        location: GeolocationPosition | null; 
        lastError: GeolocationPositionError | null;
        locationToggle: boolean;
        setLocationWatch: (value: boolean) => void;
};

export const useGps = () => {
    

    const [location, setLocation] = useState<GeolocationPosition | null>(null)
    const [lastError, setLastError] = useState<GeolocationPositionError | null>(null)
    const [locationToggle, setLocationToggle] = useState(false)
    const watchId = useRef(0)
    //const isMounted = useRef(false); //geolocation is enabled

    /*
    timestamp: number;
    coords <GeolocationCoordinates>:
        {
            accuracy: number;
            altitude: number | null;
            altitudeAccuracy: number | null;
            heading: number | null;
            latitude: number;
            longitude: number;
            speed: number | null;
        }
        
    */

    //Default location set to townsville
    const defaultLocation = {
        lat: -19.264418148878445,
        lng: 146.76353648145167,
      };

    const startLocationWatch = () => {
        console.log('startLocationWatch');
        watchId.current = watchCurrentLocation(
          (position) => {
            //if (isMounted.current) {
                console.log(position);
                setLocation(position);
            //  }
          },
          (error) => {
            //if (isMounted.current) {
                console.log(error);
                setLastError(error);
           // }
          }
        )
    }
    
    const cancelLocationWatch = () => {
        console.log('cancelLocationWatch');
        stopWatchingLocation(watchId.current);
        setLocation(null);
        setLastError(null);
        //isMounted.current = false;
    }
    
    const setLocationWatch = (flag: boolean) => {
        console.log('setLocationWatch: ',flag);
        //If set to turn on and it is enabled
        if (flag && "geolocation" in navigator) {
            console.log('Tracking enabled');
            setLocationToggle(true);
            return;
        } else if(flag) {//If not avaible and trying to turn on
            console.log("Tracking Not Available");
            setLocationToggle(false);
            return;
        } 
        
        console.log("Turn off");
        setLocationToggle(flag);
    }

    // execution after render when locationToggle is changed
    useEffect(() => {
        console.log('useEffect: locationToggle', locationToggle);
        if (locationToggle) {
            //isMounted.current = true;
            startLocationWatch();
        } else {
            cancelLocationWatch()
        }

        //Clean up
        return () => cancelLocationWatch();
    }, [locationToggle]);

    // mount / unmount
    /*useEffect(() => {
        console.log('mount / unmount');
        cancelLocationWatch()
    }, []);*/

    const api:Gps = {
        defaultLocation,
        location, 
        lastError, 
        locationToggle,
        setLocationWatch
    };

    return api; 
};

export default useGps;