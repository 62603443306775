import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import './Debug.css';

import {Api} from '../data/api';

import {Gps} from '../data/gps';
import { count } from 'console';

const Debug: React.FC<{gps: Gps, api: Api}> = ({gps, api})=> {
  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>GPS Data</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">GPS Data</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ul>
          <li>Timestamp: {gps.location?.timestamp}</li>
          <li>Accuracy: {gps.location?.coords.accuracy}</li>
          <li>Altitude: {gps.location?.coords.altitude}</li>
          <li>Altitude Accuracy: {gps.location?.coords.altitudeAccuracy}</li>
          <li>Heading: {gps.location?.coords.heading}</li>
          <li>Latitude: {gps.location?.coords.latitude}</li>
          <li>Longitude: {gps.location?.coords.longitude}</li>
          <li>Speed: {gps.location?.coords.speed}</li>
          <li>Locations: {api.locations.length}</li>
          <li>Tags: {api.tags.length}</li>
          <li>Routes: {api.routes.length}</li>
          <li>Codes: {api.codes.length}</li>
        </ul>
        <IonButton onClick={() => gps.setLocationWatch(!gps.locationToggle)}>{gps.locationToggle ? "Turn Off" : "Turn On"}</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Debug;
