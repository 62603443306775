
import { IonBadge, IonButton, IonButtons, 
    IonCol, 
    IonContent, IonFab, IonFabButton, 
    IonFabList, IonGrid, IonHeader, IonIcon, IonImg, 
    IonLabel, 
    IonPage, IonRow, IonSpinner, IonTitle, IonToolbar, useIonModal } from '@ionic/react';

import * as Constants from '../components/Map';

import { GoogleMap, useJsApiLoader , Marker ,Circle, HeatmapLayer} from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import './Map.css';
import {Gps} from '../data/gps';
import {Api,Location, Tag} from '../data/api';
import LocationDetailPage from './LocationDetailPage';
import ReactDOM from 'react-dom';
import { arrowUpCircle, colorPaletteOutline, eyeOffOutline, eyeOutline, flashlightOutline, glassesOutline, listOutline, locateOutline, locationSharp, logoAlipay, logoAmazon, logoAndroid, logoFacebook, logoTwitter, micCircleOutline, micOutline, scale, starOutline, trailSignOutline } from 'ionicons/icons';


type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization" )[];

const googleMapOptions = {
  styles: Constants.google_map_style_dark,
  disableDefaultUI: true,
  zoomControl: true,
  zoomControlOptions: {
    position: 7
  }
};

const LocationItem: React.FC<{location: Location, api: Api}> = ({location, api}) => {

  const handleDismiss = () => {
    dismissTrip();
  }

  const [presentLocation, dismissTrip] = useIonModal(LocationDetailPage, {
    api: api,
    location: location,
    onDismiss: handleDismiss,
  });

  return (
    <Marker
    onClick={()=>presentLocation()}
      position ={ {
        lat: location.lat,
        lng: location.lng
      } }
      icon = {{url: location.icon}}
    >
    </Marker>
  )
};

const IconControl: React.FC = () => {
  return (
    <img alt="SOTS 2020" src="/img/SotsStar.png" style={{height: 100}} />
  )
};

const libraries: Libraries = ['visualization'];

const StableMap: React.FC<{gps: Gps, api: Api, handleClick: () => void}> = ({gps,api, handleClick}) => {
  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [zoom, setZoom] = React.useState<number | undefined>(10)
  const [folowPosition, setFolowPosition] = useState<boolean>(true);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA1bDgTpKU2TsCK86ekDnwGswNUcV2feis",
    libraries
  });

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1
  }
  
  const [currentLoc, setCurrentLoc] = React.useState(gps.defaultLocation);

  

  useEffect(() => {
    console.log('Location Changed', gps.location);
    if(gps.locationToggle && gps.location && folowPosition){
      setCurrentLoc({
        lat: gps.location.coords.latitude,
        lng: gps.location.coords.longitude
      });
    }
    
  }, [gps.location, folowPosition, gps.locationToggle]);

  const handelZoomChange = () => {
    if(!map) return;
    setZoom(map.getZoom());
  }

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    //const useOnLoad = React.useCallback(
      const useOnLoad = (map: google.maps.Map) => {
          setMap(map);
          const logoControl = document.createElement('div');
          ReactDOM.render(<IconControl />, logoControl);
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(logoControl);
        }
    //  ,[]);

    return <GoogleMap
      options={googleMapOptions}
      onLoad={useOnLoad}
      onClick={handleClick}
      onDragStart ={handleClick}
      mapContainerStyle={containerStyle}
      center={currentLoc}
      onZoomChanged={handelZoomChange}
      zoom={zoom}
  >
    {!zoom || zoom <= 13 &&
    <HeatmapLayer data={
      api.locations.filter((location: Location) => 
      { 
        //TODO: Always show main location, office, and sponsors ie DakTech

        //Return true if any of the tags exist in the show list
        return location.tags.some( (tag: Tag) => api.tags.some( (selTag: Tag) => tag.id === selTag.id && !selTag.hide ) );
      }
      ).map((location: Location, index: number) => {
        return new google.maps.LatLng(location.lat, location.lng);
      })
    }/>}
    {gps.location?.coords.latitude && gps.location.coords.longitude &&
    (<><Marker
        icon={
          {
              path: gps.location.coords.heading ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW : google.maps.SymbolPath.CIRCLE, 
              scale: 3,
              strokeColor: "#FFFFFF",
              fillColor: "#0000FF",
              fillOpacity: 1,
              rotation: gps.location.coords.heading ? gps.location.coords.heading : 0
          }
      }
      position={ {
        lat: gps.location.coords.latitude,
        lng: gps.location.coords.longitude
      } }
    >
    </Marker>
    <Circle
    // required
    center={{
      lat: gps.location.coords.latitude,
      lng: gps.location.coords.longitude
    }}
    radius={gps.location.coords.accuracy}
    // required
    options={circleOptions}
  /></>
    )}
    {
    zoom && zoom > 13 &&
    api.locations && api.locations.filter((location: Location) => 
      { 
        //TODO: Always show main location, office, and sponsors ie DakTech
        console.log(location.tags);

        //Return true if any of the tags exist in the show list
        return location.tags.some( (tag: Tag) => api.tags.some( (selTag: Tag) => tag.id === selTag.id && !selTag.hide ) );
      }
      ).map((location: Location, index: number) => (
      <LocationItem 
      key={index}
      location ={location}
      api={api}
    >
    </LocationItem>
      ))}
  </GoogleMap>
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <IonSpinner />

};

const Map: React.FC<{gps: Gps, api: Api,}> = ({gps,api}) => {

  const setTagState = (clickedTag: Tag, e: React.MouseEvent<HTMLIonFabButtonElement, MouseEvent>) => {
    api.tags.map(tag => {
      if (clickedTag.id === tag.id){        
        api.updateTag({...tag, hide: !tag.hide});
      }
    });
  }

  const getTagState = (tagId: number) => {
    const tmpTag = api.tags.find((tag) => tag.id === tagId);
    
    if (tmpTag?.hide !== true){
      return "warning";
    } else if (tmpTag.hide === undefined) {
      return ""
    } else {
      return ""
    }
    
  }

  const TagComponent: React.FC<{tag: Tag}> = ({tag}) => {
    //const tmpTag = api.tags.find((tag) => tag.id === tagId); 

    const iconURL = + tag.icon; 


    return (<>
          <IonFabButton className="tag" onClick={(e) => {setTagState(tag, e); setFabActivated(true)}} color={getTagState(tag.id)}>
                    <img src={tag.icon}/>
          </IonFabButton>
          <IonBadge className="tag-title" style={tag.hide ? {opacity: 0.5} : {}} color={tag.hide ? "light" : "warning"}>{tag.title}</IonBadge>
      </>
    )
  }

  

  // window.addEventListener('click', 
  const [fabActivated, setFabActivated] = useState<boolean>(false); 

  const handleClick = () => {
    setFabActivated(false); 
  }

  console.log(fabActivated); 

  return (
    <IonPage>
      <IonContent fullscreen>
        
          <StableMap api={api} gps={gps} handleClick={handleClick} />

          <IonFab className="ion-margin-bottom" activated={fabActivated} vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton color="warning"><IonIcon icon={arrowUpCircle}></IonIcon></IonFabButton>
          <IonFabList side="top" className="tags">

            {api.tags.map((tag: Tag) => {
              return (
                <TagComponent tag={tag}/>
              ); 
            })}
          </IonFabList>
        </IonFab>
        <IonFab className="ion-margin-bottom" vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton className="ion-margin-top" onClick={() => gps.setLocationWatch(!gps.locationToggle)} color={gps.locationToggle ? "success" : "secondary"}>
            <IonIcon icon={locateOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default Map;

